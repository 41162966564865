<template>
  <div class="je-pasySuccess">
    <div class="box_top">
      <Header />
    </div>
    <div class="center-container">
      <img
        src="../../../../assets/images/error.png"
        alt=""
      > <br><br>
      <span
        class="font-size-22"
        style="color: #FA564F"
      >支付未成功！</span>
      <div class="sm">
        该订单支付异常，请您前往“
        <span
          class="buid"
          @click="openOrders"
        >
          我的订单
        </span>
        ”中，进行重新支付。
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header';

export default {
  name: 'PayFailure',
  components: {
    Header,
  },
  data() {
    return {};
  },
  methods: {
    // 打开已购列表
    openOrders() {
      this.$router.push({
        path: '/alreadyList',
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import '../../style/shop/util.less';
</style>
<style lang="less">
.je-pasySuccess {
  position: relative;
  .el-backtop {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 4px;
    width: 52px;
    height: 52px;
    right: 2% !important;
    bottom: 43px !important;
    font-size: 24px;
    color: #ff3041;

    &:hover {
      background: #ff3041;

      > i {
        color: #ffffff;
      }
    }
  }

  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }

  .center-container {
    width: 1250px;
    margin: 100px auto 0 auto;
    text-align: center;
    margin-bottom: 140px;
    font-size: 18px;
    color: #3f3f3f;
    padding-top: 15px;
    img {
      font-size: 0;
    }
    .buid {
      color: #376bd0;
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
    }
    .sm {
      margin-top: 20px;
    }
  }
  .vip-container {
    width: 1250px;
    margin: 0 auto;
  }
}
</style>
