import { render, staticRenderFns } from "./payFailure.vue?vue&type=template&id=129aeb68&scoped=true&"
import script from "./payFailure.vue?vue&type=script&lang=js&"
export * from "./payFailure.vue?vue&type=script&lang=js&"
import style0 from "./payFailure.vue?vue&type=style&index=0&id=129aeb68&lang=less&scoped=true&"
import style1 from "./payFailure.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129aeb68",
  null
  
)

export default component.exports